import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CanvasContext from "./CanvasContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const AIChatContext = createContext({});

export const AIChatProvider = ({ children }) => {
  const { siteRecord } = useContext(CanvasContext);
  const [chatHistory, setChatHistory] = useState([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [cancelToken, setCancelToken] = useState(null);

  const cancelAxiosRequest = useCallback(() => {
    if (cancelToken) {
      cancelToken.cancel("Request canceled by the user.");
      setCancelToken(null);
    }
  }, [cancelToken]);

  const axiosPrivate = useAxiosPrivate();

  const [offset, setOffset] = useState(0);
  const limit = 5;

  const fetchChat = useCallback(async () => {
    const siteId = siteRecord._id;
    setIsChatLoading(true);
    try {
      const resp = await axiosPrivate.get(
        `/chat/website/${siteId}?offset=${offset}&limit=${limit}`
      );
      if (resp.data && resp.data.length > 0) {
        let chatHistoryFromDB = [];
        console.log("asd", resp);
        resp.data.forEach((chat) => {
          if (chat.textResponse) {
            chatHistoryFromDB = [
              ...chatHistoryFromDB,
              { content: chat.textResponse, chatType: "AI" },
              { content: chat.userPrompt, chatType: "user" },
            ];
          } else {
            chatHistoryFromDB = [
              ...chatHistoryFromDB,
              { content: chat.userPrompt, chatType: "user" },
            ];
          }
        });
        setChatHistory((curr) => [...curr, ...chatHistoryFromDB]);
        setOffset(offset + limit);
      }
    } catch (err) {
      console.log(err);
    }
    setIsChatLoading(false);
  }, [offset, siteRecord]);

  useEffect(() => {
    if (siteRecord._id) {
      fetchChat();
    }
  }, [siteRecord]);

  return (
    <AIChatContext.Provider
      value={{
        chatHistory,
        setChatHistory,
        isWorking,
        setIsWorking,
        errMsg,
        setErrMsg,
        fetchChat,
        isChatLoading,
        setIsChatLoading,
        cancelToken,
        setCancelToken,
        cancelAxiosRequest,
      }}
    >
      {children}
    </AIChatContext.Provider>
  );
};

export default AIChatContext;
